import React from 'react'
import Button from './Button'
import formatCurrency from '../utils/formatCurrency';
import { gHeight, isMobile } from '../utils/getBodySizes';
import { getDomain, scrollTopIframe } from '../utils/domainUtility';

const Card = ({ vehicle }) => {

    let domain = 'http://localhost';

    if (window.location !== window.parent.location) {
        domain = getDomain(document.referrer);
    }

    const handleModal = () => {
        const modal = document.querySelector('.modal');
        const modalBody = modal.querySelector('.modal--content-body');
        const modalWrapper = modal.querySelector('.modal--wrapper');
        const modalTitle = modalBody.querySelector('h5');
        const modalContent = modalBody.querySelector('p');

        modalTitle.innerText = (vehicle.category === 8 ? vehicle.car_model.name + "® " + vehicle.apr : vehicle.car_model.name + "®")
        modalContent.innerText = vehicle.legal

        modal.classList.add("active");

        if (!isMobile) {
            modalWrapper.style.height = gHeight() + "px";
        } else {
            modalWrapper.classList.add("mobile");
        }

        scrollTopIframe(domain);

    }

    return (


        <div div className="card active-card" data-category-id={vehicle.category} data-vehicle-id={vehicle.car_model.id}>
            <div className="card--title">
                <h3 className="">{vehicle.car_model.id === 3560 ? "Rogue" : vehicle.car_model.name}<span>®</span></h3>
                <p className="">{(vehicle.category === 8 ? <><div className="text-xs -mt-1 text-center">{vehicle.car_model.subtitle}</div><div className="text-xl -mt-1 text-center">{vehicle.apr}</div> </> : vehicle.car_model.year)}</p>
            </div >
            <div className="card--image -mt-5">
                <img src={vehicle.car_model.image} width={400} height={300} alt={vehicle.car_model.name} />
            </div>

            {
                (vehicle.car_model.id !== 26 || vehicle.car_model.id !== 38) &&
                <div className="text-center text-xs m-[-28px]">
                    &nbsp; <br />
                    &nbsp;
                </div>
            }

            {
                (vehicle.car_model.id === 26 || vehicle.car_model.id === 38) &&
                <div className="text-center text-xs mb-[-10px] mt-[-30px] ">
                    <strong className=''>Escoge entre</strong>
                </div>
            }

            <div className="card--content">

                {vehicle.category === 8 &&
                    <div className="card--content-info relative">
                        <div className="text-xs absolute -top-3">Desde</div>
                        <div className="price relative">
                            <span>$</span>
                            {formatCurrency(vehicle.price)}
                            <button onClick={handleModal}>
                                [*]
                            </button>
                        </div>
                        <strong>&nbsp;</strong>
                    </div>
                }

                {vehicle.price !== 0 && vehicle.category !== 8 &&
                    <div className="card--content-info relative">
                        <div className="text-xs absolute -top-3">&nbsp;</div>
                        <div className="price relative">
                            <span>$</span>
                            {formatCurrency(vehicle.price)}
                            <button onClick={handleModal}>
                                {vehicle.car_model.id === 26 ? "(1)" : "[*]"}
                            </button>
                        </div>
                        <strong>de BONO</strong>
                    </div>
                }

                {vehicle.apr !== '' && vehicle.category !== 8 &&
                    <div className="card--content-info relative">
                        <div className="text-xs absolute -top-3">Desde</div>
                        <div className="price relative">
                            {vehicle.apr}
                            <span>%</span>
                            <button onClick={handleModal}>
                                {(vehicle.car_model.id === 26 && vehicle.car_model.id !== 3560) ? "(2,3)" : vehicle.car_model.id === 3560 ? "(1,2)" : "[*]"}
                            </button>
                        </div>
                        <strong>APR</strong>

                    </div>
                }



            </div>
            {/* {vehicle.car_model.id === 34 &&
                <div class="card--content-notes">
                    <small>(aplica solo al grado S) </small>
                </div>
            } */}

            <div className="card--action">
                {vehicle.call_to_actions.map((cta, index) =>
                    <Button
                        key={index}
                        label={cta.label}
                        urlTarget={cta.url}
                        CarModelID={vehicle.car_model.id}
                        CarModelYear={vehicle.car_model.year}
                        CarModelName={vehicle.car_model.name}
                        CTAHandle={handleModal}
                    />
                )}
            </div>
        </div >
    )
}

export default Card