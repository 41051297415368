import _ from "lodash";

const getCategory = (index) => {
    const categories = [
        "Bono",
        "APR",
        "Bono o APR",
        "Pago mensual",
        "Piezas y servicio",
        "Descuentos",
        "Precios especiales",
        "Piezas y servicio",
        "Piezas y servicio",

    ];

    if (index >= 0 && index < categories.length) {
        return _.get(categories, index - 1);
    }
    else {
        return null;
    }
}

export default getCategory